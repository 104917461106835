exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-en-about-camarades-js": () => import("./../../../src/pages/en/about-camarades.js" /* webpackChunkName: "component---src-pages-en-about-camarades-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-portfolio-and-initiatives-js": () => import("./../../../src/pages/en/portfolio-and-initiatives.js" /* webpackChunkName: "component---src-pages-en-portfolio-and-initiatives-js" */),
  "component---src-pages-en-syrf-guide-js": () => import("./../../../src/pages/en/syrf-guide.js" /* webpackChunkName: "component---src-pages-en-syrf-guide-js" */),
  "component---src-pages-en-sysphar-js": () => import("./../../../src/pages/en/sysphar.js" /* webpackChunkName: "component---src-pages-en-sysphar-js" */),
  "component---src-pages-en-tools-and-resources-js": () => import("./../../../src/pages/en/tools-and-resources.js" /* webpackChunkName: "component---src-pages-en-tools-and-resources-js" */),
  "component---src-pages-en-wiki-js": () => import("./../../../src/pages/en/wiki.js" /* webpackChunkName: "component---src-pages-en-wiki-js" */),
  "component---src-pages-ferramentas-e-recursos-js": () => import("./../../../src/pages/ferramentas-e-recursos.js" /* webpackChunkName: "component---src-pages-ferramentas-e-recursos-js" */),
  "component---src-pages-guia-syrf-js": () => import("./../../../src/pages/guia-syrf.js" /* webpackChunkName: "component---src-pages-guia-syrf-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-e-iniciativas-js": () => import("./../../../src/pages/portfolio-e-iniciativas.js" /* webpackChunkName: "component---src-pages-portfolio-e-iniciativas-js" */),
  "component---src-pages-sobre-a-camarades-js": () => import("./../../../src/pages/sobre-a-camarades.js" /* webpackChunkName: "component---src-pages-sobre-a-camarades-js" */),
  "component---src-pages-sysphar-js": () => import("./../../../src/pages/sysphar.js" /* webpackChunkName: "component---src-pages-sysphar-js" */),
  "component---src-pages-wiki-js": () => import("./../../../src/pages/wiki.js" /* webpackChunkName: "component---src-pages-wiki-js" */),
  "component---src-templates-post-en-js": () => import("./../../../src/templates/post_en.js" /* webpackChunkName: "component---src-templates-post-en-js" */),
  "component---src-templates-post-pt-js": () => import("./../../../src/templates/post_pt.js" /* webpackChunkName: "component---src-templates-post-pt-js" */)
}

